@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

.homeLink {
    color: white;
    margin-right: 12px;
    text-decoration: none;
}

.headerNav {
    color: white;
    margin-right: 12px;
    text-decoration: none;
}

.content-container {
    min-height: calc(100vh - 34px);
}

.footer--pin {
    position: relative;
    left: 0;
    bottom: 0;
  }