.content{
    min-width: 300px;
    max-width: 600px;
    margin: 0 auto;

}

.controls {
    align-items: center;
    margin-bottom: 20px;
    margin-top: 20px;
}

.loginPromptContent{
    padding: 20px;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 5px;
}