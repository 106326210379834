

.playgroundContent{
    min-width: 300px;
    max-width: 600px;
    margin: 0 auto;
    margin-top: 24px;
    margin-bottom: 24px;
}

.playgroundList {
    height: 400px;
    overflow: auto;
}



