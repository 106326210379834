.main {
   width: 25%;
   margin: auto;
   text-align: center;
   border-radius: 8px;
}

.clue {
   font-size: 1.5rem;
   font-weight: bold;
   margin-bottom: 12px;
   border: 1px solid #ccc;
   border-radius: 12px;
   width: 100%;
   padding: 12px;
}

.credits {
   font-size: 0.8rem;
   width: 100%;
   padding: 12px;
}

.solution {
   font-size: 1.1rem;
   font-weight: bold;
}

.arrow {
   margin-right: 12px;
   color: lightgray;
}

.grow {
   color: lightgrey
}


.blink {
    animation: blinker 1s linear infinite;
}
  
@keyframes blinker {
   50% {
      opacity: 0;
    }
}
